export const GFPP = {
  HELP_ID: {
    FORM_CONTAINER: 'bf285f63-1b78-4de5-b99e-f22b5ee1b33c',
    REGISTRATION_FORM_CONTAINER: '72aa417d-2ea3-45ec-8ff7-01be0f3783a7',
    FORM_CONTAINER_MOBILE: '1e7e55ab-7637-4292-9469-28a501df6474',
    FIELD: '79200180-9916-4cab-a066-7211459c0709',
    REGISTRATION_FIELD: '79277e88-a0b9-4eba-bc6c-4be33e1491d2',
    FIELD_MOBILE: '6ddb0b8b-a4af-471f-a63e-ef3cb1084892',
    CAPTCHA: 'a802652a-b669-4d54-b507-f1e4221a4144',
    SUBMIT_BUTTON: '949303cc-13bf-4d95-ba5c-72d2db07b69e'
  },
  KEEP_DEFAULT: 'DEFAULT',
  REMOVE: 'HIDE',
}

export const GFPP_ACTIONS = {
  ADD: 'add',
  SETTINGS: 'settings',
  LAYOUT: 'layout',
  DESIGN: 'design',
  ANIMATION: 'animation',
  UPGRADE: 'upgrade',
  CONNECT: 'connect',
  LINK: 'link',
  CUSTOM_CONNECT: 'customConnect'
}

export enum GFPP_IDS {
  FORM_SETTINGS = 'form-settings',
  MANAGE_FIELDS = 'manage-fields',
  ADD_FIELD = 'add-field',
  MANAGE_SUBSCRIBERS = 'manage-subscribers',
  FIELD_SETTINGS = 'field-settings',
  FORM_STYLE = 'form-style',
  FORM_LAYOUT = 'form-layout',
  SUBMIT_SETTINGS = 'submit-settings',
  RECAPTCHA_HELP = 'recaptcha-help',
}
