import * as _ from 'lodash'
import { undoable, withBi, isInputField } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormField } from '../api-types'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON, ROLE_TITLE } from '../../../constants/roles'
import {
  DEFAULT_FIELD_MARGIN,
  FORM_PADDING,
  PADDING_FROM_TITLE,
  TOP_PADDING,
  FieldsLayout,
} from './constants/layout-settings'
import { EXTRA_COLSPANS } from './constants/columns'
import CoreApi from '../core-api'
import { getFieldsLayout } from './constants/layout-settings'
import { shiftListItems } from '../../../utils/utils'
import {
  FieldPropHandler,
  getTextAlignmentProp,
  getLabelMarginProp,
  getLabelPaddingProp,
  getInputTextPaddingProp,
} from './utils'
import { FIELDS_THAT_SUPPORT_SHOWING_LABEL } from './constants/field-config'

export default class LayoutApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
  }

  public updateFieldLayout(fieldRef: ComponentRef, newLayout) {
    return this.boundEditorSDK.components.layout.update({
      componentRef: fieldRef,
      layout: newLayout,
    })
  }

  @undoable()
  public async updateFieldsTextAlignment(
    componentRef: ComponentRef,
    fields: FormField[],
    alignment
  ) {
    await this._updateFieldsProp(fields, getTextAlignmentProp, alignment)
    return this.coreApi.setComponentConnection(componentRef, { textAlignment: alignment })
  }

  @undoable()
  public async updateFieldsLabelMargin(componentRef: ComponentRef, fields: FormField[], margin) {
    await this._updateFieldsProp(fields, getLabelMarginProp, margin)
    return this.coreApi.setComponentConnection(componentRef, { labelMargin: margin })
  }

  @undoable()
  public async updateFieldsLabelPadding(componentRef: ComponentRef, fields: FormField[], value) {
    await this._updateFieldsProp(fields, getLabelPaddingProp, value)
    return this.coreApi.setComponentConnection(componentRef, { labelPadding: value })
  }

  @undoable()
  public async updateFieldsInputTextPadding(
    componentRef: ComponentRef,
    fields: FormField[],
    value
  ) {
    await this._updateFieldsProp(fields, getInputTextPaddingProp, value)
    return this.coreApi.setComponentConnection(componentRef, { textPadding: value })
  }

  private async _updateFieldsProp(
    fields: FormField[],
    getPropHandler: FieldPropHandler,
    value: string
  ) {
    const fieldsUpdates = []

    fields.map(field => {
      const { componentRef, componentType } = field
      const fieldUpdateProp = getPropHandler(componentType, value)

      if (!fieldUpdateProp) return

      fieldsUpdates.push(
        this.boundEditorSDK.components.properties.update({
          componentRef: componentRef,
          props: fieldUpdateProp,
        })
      )
    })

    return Promise.all(fieldsUpdates)
  }

  public async showFieldsTitle(componentRef: ComponentRef, fields: FormField[]) {
    const fieldsUpdates = []

    fields.map(field => {
      if (!FIELDS_THAT_SUPPORT_SHOWING_LABEL.includes(field.componentType)) {
        return
      }

      fieldsUpdates.push(
        this.boundEditorSDK.components.data.update({
          componentRef: field.componentRef,
          data: { label: field.label },
        })
      )
    })

    const updateConnection = this.coreApi.setComponentConnection(componentRef, {
      showFieldsTitle: true,
    })

    return Promise.all([...fieldsUpdates, updateConnection])
  }

  public async hideFieldsTitle(componentRef: ComponentRef, fields: FormField[]) {
    const fieldsUpdates = []

    fields.map(field => {
      if (!FIELDS_THAT_SUPPORT_SHOWING_LABEL.includes(field.componentType)) {
        return
      }

      fieldsUpdates.push(
        this.boundEditorSDK.components.data.update({
          componentRef: field.componentRef,
          data: { label: '' },
        })
      )
    })

    const updateConnection = this.coreApi.setComponentConnection(componentRef, {
      showFieldsTitle: false,
    })

    return Promise.all([...fieldsUpdates, updateConnection])
  }

  public async updateFormWidth(componentRef: ComponentRef, width: number, spacing: number) {
    const prevWidth = (await this.boundEditorSDK.components.layout.get({
      componentRef,
    })).width

    const fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const inputFields = fields.filter(field => isInputField(field.role))
    const isFieldsSharingTheRow = (field, index) =>
      index > 0 && field.y === inputFields[index - 1].y

    const rows: FormField[][] = inputFields.reduce((acc, curr, index) => {
      if (isFieldsSharingTheRow(curr, index)) {
        acc[acc.length - 1] = acc[acc.length - 1].concat(curr)
      } else {
        acc = acc.concat([[curr]])
      }
      return acc
    }, [])

    const fieldsUpdates = []
    const lastY = rows.reduce((prevY, row) => {
      const fieldsInRow = row.length
      const elementWidth = (width - spacing * (fieldsInRow - 1)) / fieldsInRow

      const firstElement = _.first(row)
      const newY = prevY >= 0 ? prevY + spacing : firstElement.y

      row.reduce((prevX, field) => {
        const newX = prevX >= 0 ? prevX + spacing + elementWidth : field.x
        fieldsUpdates.push(
          this.boundEditorSDK.components.layout.update({
            componentRef: field.componentRef,
            layout: { width: elementWidth, x: newX, y: newY },
          })
        )
        return newX
      }, -1)

      return newY + firstElement.height
    }, -1)

    const lastElement = _.last(rows)[0]
    const heightDiff = lastElement.y + lastElement.height - lastY

    await Promise.all([
      ...fieldsUpdates,
      ...(await this._fixButtonAndMessage(
        componentRef,
        heightDiff,
        prevWidth,
        width,
        lastElement.x,
        spacing
      )),
    ])

    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { width },
    })
  }

  private async _getButtonAndMessageLayouts(formRef: ComponentRef) {
    const layouts = await this.getChildrenLayouts(formRef, [ROLE_SUBMIT_BUTTON, ROLE_MESSAGE])
    return {
      submitButtonLayout: layouts.find(field => (<FormField>field).role === ROLE_SUBMIT_BUTTON),
      messageLayout: layouts.find(field => (<FormField>field).role === ROLE_MESSAGE),
    }
  }

  private async _fixButtonAndMessage(
    formRef: ComponentRef,
    heightDiff: number,
    prevWidth: number,
    newWidth: number,
    lastFieldX: number,
    spacing: number,
  ) {
    const isMobile = formRef.type === 'MOBILE'
    const layouts = await this._getButtonAndMessageLayouts(formRef)

    const {
      x: buttonX,
      y: buttonY,
      width: buttonWidth,
      height: buttonHeight,
      componentRef: buttonCompRef,
    } = <FormField>layouts.submitButtonLayout

    const calcXDecrease = (elmX, elmWidth) => {
      const widthDiff = prevWidth - newWidth
      const leftSpace = elmX - lastFieldX
      const sidesRadio = leftSpace / (prevWidth - elmWidth)
      return widthDiff * sidesRadio
    }

    let buttonPromise
    const buttonNewY = buttonY - heightDiff
    if (buttonWidth === prevWidth || isMobile) {
      buttonPromise = this.boundEditorSDK.components.layout.update({
        componentRef: buttonCompRef,
        layout: { width: newWidth, y: buttonNewY, x: lastFieldX },
      })
    } else {
      buttonPromise = this.boundEditorSDK.components.layout.update({
        componentRef: buttonCompRef,
        layout: { x: buttonX - calcXDecrease(buttonX, buttonWidth), y: buttonNewY },
      })
    }

    let messagePromise = Promise.resolve()
    if (layouts.messageLayout) {
      const { y: messageY, componentRef: messageCompRef, width: messageWidth } = <FormField>(
        layouts.messageLayout
      )
      const messageNewY = isMobile ? buttonNewY + buttonHeight + spacing : messageY - heightDiff
      messagePromise = this.boundEditorSDK.components.layout.update({
        componentRef: messageCompRef,
        layout: { width: newWidth * (messageWidth / prevWidth), y: messageNewY },
      })
    }

    return [buttonPromise, messagePromise]
  }

  public updateFieldsLayoutADI(
    componentRef: ComponentRef,
    { fieldsIndex = null, showTitles = false } = {}
  ) {
    return this._updateFieldsLayout(componentRef, 1, { isAdiLayout: true, fieldsIndex, showTitles })
  }

  private _getCruicalElementHeight(elementLayout, padding) {
    if (!elementLayout) return 0
    return elementLayout.height + padding
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.formLayoutPanel.CHANGE_LAYOUT,
    endEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED,
  })
  public updateFieldsLayout(componentRef: ComponentRef, columnsNumber: number, _biData = {}) {
    this._updateFieldsLayout(componentRef, columnsNumber, {})
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.formLayoutPanel.CHANGE_LAYOUT,
  })
  public async updateFieldsLayoutByCustomSpacing({
    componentRef,
    formLayout,
    rowSpacing,
    colSpacing,
    showFieldsTitle
  }, _biData = {}) {
    await this._updateFieldsLayout(componentRef, formLayout, { rowSpacing, colSpacing, allFieldsTypes: false, showTitles: showFieldsTitle })
    return this.coreApi.setComponentConnection(componentRef, {
      spaceBetweenRows: rowSpacing,
      spaceBetweenCols: colSpacing,
    })
  }

  private async _updateFieldsLayout(
    componentRef: ComponentRef,
    columnsNumber: number,
    {
      isAdiLayout = false,
      fieldsIndex = null,
      showTitles = false,
      allFieldsTypes = true,
      rowSpacing = 0,
      colSpacing = null,
    } = {}
  ): Promise<void> {
    let fields = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes,
    })

    if (fieldsIndex) {
      const { removedIndex, addedIndex } = fieldsIndex
      fields = shiftListItems(_.cloneDeep(fields), removedIndex, addedIndex)
    }
    const fieldsLayout = getFieldsLayout(isAdiLayout, showTitles, rowSpacing, colSpacing)

    const titleHeight = await this.updateComponentByTitle(componentRef)
    const layoutCalc = await this._calcUpdateFields(componentRef, fields, {
      columnsNumber,
      titleHeight,
      fieldsLayout,
      rowSpacing,
      colSpacing
    })
    const { height: oldHeight } = await this.boundEditorSDK.components.layout.get({ componentRef })
    const { submitButtonLayout, messageLayout } = await this._getButtonAndMessageLayouts(
      componentRef
    )

    const newHeight =
      layoutCalc.y +
      layoutCalc.maxHeight +
      fieldsLayout.lastFieldPadding +
      this._getCruicalElementHeight(submitButtonLayout, fieldsLayout.rolePadding.SUBMIT) +
      this._getCruicalElementHeight(messageLayout, fieldsLayout.rolePadding.MESSAGE)

    if (oldHeight < newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }

    await Promise.all([
      ...layoutCalc.updates.map(({ componentRef, layout }) =>
        this.boundEditorSDK.components.layout.update({
          componentRef,
          layout,
        })
      ),
      ...this._updateSubmitAndMessage(submitButtonLayout, messageLayout, layoutCalc, fieldsLayout),
    ])

    if (oldHeight > newHeight) {
      await this.coreApi.addHeightToContainers(componentRef, newHeight - oldHeight)
    }

    await this.coreApi.setComponentConnection(componentRef, { columns: columnsNumber })
  }

  public async updateComponentByTitle(componentRef: ComponentRef): Promise<number> {
    const titleComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_TITLE)
    if (!titleComponentRef) {
      return 0
    }

    const { height } = await this.boundEditorSDK.components.layout.get({
      componentRef: titleComponentRef,
    })
    await this.boundEditorSDK.components.layout.update({
      componentRef: titleComponentRef,
      layout: { x: FORM_PADDING, y: TOP_PADDING },
    })

    return height
  }

  public async getChildrenLayouts(componentRef: ComponentRef, childRoles: string[] | string) {
    if (_.isString(childRoles)) {
      childRoles = [<string>childRoles]
    }
    const pred = role => _.includes(childRoles, role)

    const childComps = await this.boundEditorSDK.components.getChildren({ componentRef })
    const childrenLayouts = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.coreApi.getComponentConnection(child)
        if (pred(role)) {
          const layout = await this.boundEditorSDK.components.layout.get({ componentRef: child })
          return { componentRef: child, role, ...layout }
        }
        return null
      })
    )
    return _.filter(childrenLayouts, x => !!x)
  }

  private _updateSubmitAndMessage(submitLayout, messageLayout, { y, maxHeight }, FIELDS_LAYOUT) {
    let submitPadding = 0
    const updates = []
    if (submitLayout) {
      submitPadding = submitLayout.height + FIELDS_LAYOUT.rolePadding.SUBMIT
      updates.push(
        this.boundEditorSDK.components.layout.update({
          componentRef: submitLayout.componentRef,
          layout: { y: y + maxHeight + FIELDS_LAYOUT.rolePadding.SUBMIT },
        })
      )
    }
    if (messageLayout) {
      updates.push(
        this.boundEditorSDK.components.layout.update({
          componentRef: messageLayout.componentRef,
          layout: { y: y + maxHeight + FIELDS_LAYOUT.rolePadding.MESSAGE + submitPadding },
        })
      )
    }
    return updates
  }

  public updateYWithPadding(componentRef: ComponentRef, padding, { y, maxHeight }, key = 'y') {
    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { [key]: y + maxHeight + padding },
    })
  }

  public async centerComponentInsideLightbox(componentRef: ComponentRef) {
    const { width, height, x, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })

    return this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: {
        x: _.max([0, x - width / 2]),
        y: _.max([0, y - height / 2]),
      },
    })
  }

  private _calcUpdateFields(
    componentRef: ComponentRef,
    fields,
    {
      columnsNumber,
      titleHeight = 0,
      fieldsLayout,
      rowSpacing,
      colSpacing
    }: {
      columnsNumber: number
      titleHeight: number
      fieldsLayout: FieldsLayout
      rowSpacing: number
      colSpacing?: number
    }
  ) {
    const fieldMargin = colSpacing !== null ? colSpacing : DEFAULT_FIELD_MARGIN
    const reduceFunc = async (prevFieldLayout, field) => {
      const { updates, startX, x, y, maxHeight, defaultWidth, currentCol, firstFieldPerXAxis } = await prevFieldLayout
      const colSize = await this._getFieldColSize(field.componentRef, columnsNumber)
      const width = colSize * defaultWidth + (colSize - 1) * fieldMargin
      let nextCol = currentCol + colSize
      let layout = { width, x: x + width + fieldMargin, y }
      let overrideMaxHeight = -1
      if (nextCol > columnsNumber) {
        nextCol = colSize
        layout = { width, x: startX, y: y + maxHeight + fieldsLayout.heightPadding }
        overrideMaxHeight = 0
      }

      const newLayout = _.cloneDeep(layout)

      if (!firstFieldPerXAxis[field.x]) {
        firstFieldPerXAxis[field.x] = true
        newLayout.y = newLayout.y - rowSpacing
      }

      return {
        updates: [
          ...updates,
          {
            componentRef: field.componentRef,
            layout: newLayout,
          },
        ],
        startX,
        x: layout.x,
        y: layout.y,
        width,
        defaultWidth,
        maxHeight: _.max([overrideMaxHeight > -1 ? overrideMaxHeight : maxHeight, field.height]),
        currentCol: nextCol,
        firstFieldPerXAxis: { ...firstFieldPerXAxis }
      }
    }

    const getBoxConfig = async (
      componentRef: ComponentRef,
      columns: number,
      titleHeight: number
    ) => {
      const { width } = await this.boundEditorSDK.components.layout.get({ componentRef })
      const titleExtraHeight = titleHeight > 0 ? titleHeight + PADDING_FROM_TITLE : 0

      return {
        updates: [],
        startX: fieldsLayout.startX,
        currentCol: columns,
        x: fieldsLayout.formPadding,
        y: 0,
        width: 0,
        maxHeight: fieldsLayout.formPadding + titleExtraHeight - (DEFAULT_FIELD_MARGIN / 2),
        defaultWidth: fieldsLayout.defaultWidth(width, columns),
        firstFieldPerXAxis: {}
      }
    }

    return _.reduce(fields, reduceFunc, getBoxConfig(componentRef, columnsNumber, titleHeight))
  }

  private async _getFieldColSize(componentRef: ComponentRef, columns: number): Promise<number> {
    const type = await this.boundEditorSDK.components.getType({ componentRef })
    return _.min([EXTRA_COLSPANS[type] || 0, columns - 1]) + 1
  }
}
