import * as _ from 'lodash'
import { isWixEmployeeEmail, getTranslationByPlugin } from '../../utils/utils'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
} from '../../constants/roles'
import translations from './services/translations'
import {
  ComponentConnection,
  ComponentRef,
  FormFieldDefinition,
  FormSnapshot,
  Plugin,
} from './api-types'
import { SuccessActionTypes } from '../../constants/success-settings'
import RemoteApi from '../../panels/commons/remote-api'
import { EVENTS } from '../../constants/bi'
import {
  compareObjectsAndUpdateIfNeeded,
  componentsDiff,
  generateRuntimeCoreApi,
  getValidCollectionId,
  undoable,
  withBi,
  getPrimaryConnection,
} from './utils'
import LayoutApi from './layout-panel/api'
import StyleApi from './style-panel/api'
import AddFormApi from './add-form/api'
import CollectionsApi from './collections/api'
import SettingsApi, { SETTINGS_API_NAME } from './settings-panel/api'
import FirstTimeApi from './first-time-panel/api'
import ManagePanelsApi from './manage-panels/api'
import FieldsApi from './fields/api'
import PremiumApi from './premium/api'
import AppState from './app-state/app-state'
import { FieldPreset, FormsFieldPreset, FieldNameType } from '../../constants/field-types'
import {
  FieldProperties,
  getFieldProperties,
  allowCollectionSync,
} from './preset/fields/field-types-data'
import { FormPlugin } from '../../constants/plugins'
import { CRM_TAGS, CRM_TYPES } from '../../constants/crm-types-tags'
import Experiments from '@wix/wix-experiments'
import PluginsApi from './plugins/api'
import { MissingField } from '../../panels/settings-panel/components/crucial-elements/crucial-elements'
import { getActivePlugin, convertPluginsToFormsPlugins } from './plugins/utils'
import { REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES } from './plugins/registration-form'

export default class CoreApi {
  private experiments: Experiments
  private ravenInstance: any
  private remoteApi: RemoteApi
  private biLogger: any
  private origin: any

  public layout: LayoutApi
  public style: StyleApi
  public addForm: AddFormApi
  public collectionsApi: CollectionsApi
  public settings: Partial<SettingsApi>
  public firstTimePanel: FirstTimeApi
  public managePanels: ManagePanelsApi
  public fields: FieldsApi
  public premium: PremiumApi
  public appState: AppState
  public plugins: PluginsApi

  constructor(
    private boundEditorSDK,
    private editorSDK,
    {
      apis: { collectionsApi, collectionsPermissionsApi, remoteApi },
      experiments,
      ravenInstance,
      biLogger,
      origin,
    }
  ) {
    const helpers = { experiments, biLogger }
    this.collectionsApi = new CollectionsApi(
      boundEditorSDK,
      collectionsApi,
      collectionsPermissionsApi,
      helpers
    )
    this.remoteApi = remoteApi
    this.boundEditorSDK = boundEditorSDK
    this.editorSDK = editorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.biLogger = biLogger
    this.origin = origin

    this.layout = new LayoutApi(boundEditorSDK, this, helpers)
    this.style = new StyleApi(boundEditorSDK, this, helpers)
    this.addForm = new AddFormApi(boundEditorSDK, this, this.collectionsApi, remoteApi, helpers)
    this[SETTINGS_API_NAME] = new SettingsApi(boundEditorSDK, editorSDK, this, remoteApi, helpers)
    this.managePanels = new ManagePanelsApi(boundEditorSDK, editorSDK, this, helpers)
    this.fields = new FieldsApi(boundEditorSDK, this, remoteApi, helpers)
    this.premium = new PremiumApi(boundEditorSDK, this, remoteApi, helpers)
    this.appState = new AppState(boundEditorSDK, this, remoteApi, helpers)
    this.firstTimePanel = new FirstTimeApi(boundEditorSDK)
    this.plugins = new PluginsApi(this)
  }

  public getExperiments() {
    return Promise.resolve(this.experiments.all())
  }

  private async _replaySnapshot(formRef: ComponentRef, prevSnapshot: FormSnapshot) {
    const currentSnapshot = await this.takeSnapshot(formRef)

    const updateComponent = (componentRef, currComponent, prevComponent) =>
      Promise.all([
        updateConfig(componentRef, currComponent.config, prevComponent.config),
        updateLayout(componentRef, currComponent.layout, prevComponent.layout),
        updateData(componentRef, currComponent.data, prevComponent.data),
        updateProps(componentRef, currComponent.props, prevComponent.props),
      ])
    const updateForm = (componentRef, currComponent, prevComponent) =>
      Promise.all([
        updateConfig(componentRef, currComponent.config, prevComponent.config),
        updateLayout(componentRef, currComponent.layout, prevComponent.layout),
      ])

    const updateExistingComponents = () =>
      prevSnapshot.components.map(component => {
        const currComponent = currentSnapshot.components.find(
          comp => comp.componentRef.id === component.componentRef.id
        )
        if (!currComponent) return
        const componentRef = component.componentRef
        return updateComponent(componentRef, currComponent, component)
      })

    const deleteComponents = componentsToDelete =>
      componentsToDelete.map(componentRef =>
        this.boundEditorSDK.components.remove({ componentRef })
      )

    const addComponents = componentsToAdd => {
      return componentsToAdd.map(component => {
        const data = _.omit(component, ['role', 'config'])
        return this.fields.restoreField(formRef, {
          data,
          role: component.role,
          config: component.config,
        })
      })
    }
    const updateConfig = (componentRef, currConfig, prevConfig) =>
      compareObjectsAndUpdateIfNeeded(
        componentRef,
        currConfig,
        prevConfig,
        (componentRef, valueToUpdate) => this.setComponentConnection(componentRef, valueToUpdate)
      )
    const updateLayout = (componentRef, currLayout, prevLayout) =>
      compareObjectsAndUpdateIfNeeded(
        componentRef,
        currLayout,
        prevLayout,
        (componentRef, valueToUpdate) => this.layout.updateFieldLayout(componentRef, valueToUpdate)
      )
    const updateData = (componentRef, currData, prevData) =>
      compareObjectsAndUpdateIfNeeded(
        componentRef,
        currData,
        prevData,
        (componentRef, valueToUpdate) =>
          this.boundEditorSDK.components.data.update({
            componentRef,
            data: valueToUpdate,
          })
      )
    const updateProps = (componentRef, currProps, prevProps) =>
      compareObjectsAndUpdateIfNeeded(
        componentRef,
        currProps,
        prevProps,
        (componentRef, valueToUpdate) =>
          this.boundEditorSDK.components.properties.update({
            componentRef,
            props: valueToUpdate,
          })
      )
    const { formComponent: prevForm } = prevSnapshot
    const { formComponent: currForm } = currentSnapshot
    const formChangedLayout =
      prevForm.layout.height > currForm.layout.height ||
      prevForm.layout.width > currForm.layout.width
    if (formChangedLayout) {
      await updateForm(formRef, currForm, prevForm)
    }
    const { componentsToAdd, componentsToDelete } = componentsDiff(
      currentSnapshot.components,
      prevSnapshot.components
    )

    if (_.isEqual(formRef, prevForm.componentRef)) {
      await Promise.all(updateExistingComponents())
    }
    await Promise.all([...deleteComponents(componentsToDelete), ...addComponents(componentsToAdd)])

    if (!formChangedLayout) {
      await updateForm(formRef, currForm, prevForm)
    }
  }

  public async replaySnapshot(
    formRef: ComponentRef,
    prevSnapshot: FormSnapshot,
    theme: string,
    width: number,
    spacing: number
  ) {
    if (prevSnapshot) {
      await this._replaySnapshot(formRef, prevSnapshot)
    }

    if (width) {
      await this.layout.updateFormWidth(formRef, width, spacing)
    }

    if (theme) {
      await this.style.updateThemeADI(formRef, theme)
    }

    const currentSnapshot = await this.takeSnapshot(formRef)
    return currentSnapshot
  }

  public initiator() {
    return _.get(this.origin, 'initiator')
  }

  public updateOrigin(origin) {
    this.origin = origin
  }

  public async takeSnapshot(formRef: ComponentRef): Promise<FormSnapshot> {
    console.info('takeSnapshot', formRef)
    const componentsIds = await this.fields.getRawFields(formRef)
    console.info('componentsIds', componentsIds)
    const componentsData = await this.boundEditorSDK.components.get({
      componentRefs: componentsIds,
      properties: [
        'data',
        'props',
        'layout',
        'connections',
        'style',
        'componentType',
        'components',
        'skin',
      ],
    })
    console.info('componentsData', componentsData)
    let formComponent
    const componentsDataAndConfig = componentsData
      .map(component => {
        const { role, config } = _.find(component.connections, comp => comp.isPrimary)
        const comp = _.merge(_.omit(component, 'connections'), { role, config })
        if (role === ROLE_FORM) {
          const config = _.omit(comp.config, [
            'columns',
            'secondsToResetForm',
            'errorMessage',
            'plugins',
            'theme',
            'styles',
          ])
          const layout = _.pick(comp.layout, ['width', 'height'])
          formComponent = {
            componentRef: comp.componentRef,
            config: config,
            layout: layout,
          }
          return
        }

        return comp
      })
      .filter(c => c)

    if (!formComponent) {
      console.error(new Error('cannot find form component'))
    }

    const sortedFields =  _.sortBy(componentsDataAndConfig, ['layout.y', 'layout.x'])

    return {
      components: sortedFields,
      formComponent,
    }
  }

  public setBiLogger(biLogger) {
    this.biLogger = biLogger
  }

  public async connect(
    { connectionConfig, role },
    controllerRef: ComponentRef,
    connectToRef: ComponentRef
  ) {
    await this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary: true,
    })
    return { role, connectionConfig, connectToRef, controllerRef }
  }

  public async addComponentAndConnect(
    { data, connectionConfig, role },
    controllerRef: ComponentRef,
    containerRef: ComponentRef
  ) {
    const connectToRef = await this.boundEditorSDK.components.add({
      componentDefinition: data,
      pageRef: containerRef,
    })

    return this.connect({ connectionConfig, role }, controllerRef, connectToRef)
  }

  public async getFormId(componentRef) {
    const formCompRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    return formCompRef.id
  }

  public async findConnectedComponent(
    controllerRef: ComponentRef,
    childRole
  ): Promise<ComponentRef> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const obj = await Promise.all(
      childComps.map(async child => {
        const { role } = await this.getComponentConnection(child)
        return role === childRole ? child : null
      })
    )
    return <ComponentRef | null>_.find(obj, x => !!x)
  }

  private async _findConnectedComponentsByFieldType(
    controllerRef: ComponentRef,
    componentFieldType: FieldPreset
  ): Promise<ComponentRef[]> {
    const childComps = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const obj = await Promise.all(
      childComps.map(async child => {
        const { config } = await this.getComponentConnection(child)
        const fieldType = _.get(config, 'fieldType')
        return fieldType === componentFieldType ? child : null
      })
    )

    return <ComponentRef[]>_.filter(obj, x => !!x)
  }

  public async findComponentByRole(componentRef: ComponentRef, childRole) {
    const { controllerRef } = await this.getComponentConnection(componentRef)
    return this.findConnectedComponent(controllerRef, childRole)
  }

  public async getFormContainerOfAppWidget(componentRef: ComponentRef) {
    return (await this.boundEditorSDK.components.getChildren({ componentRef }))[0]
  }

  public generateRuntimeApi() {
    return generateRuntimeCoreApi(
      this,
      {
        layout: this.layout,
        style: this.style,
        settings: this.settings,
        firstTimePanel: this.firstTimePanel,
        addForm: this.addForm,
        managePanels: this.managePanels,
        fields: this.fields,
        premium: this.premium,
        appState: this.appState,
      },
      this.ravenInstance
    )
  }

  public async getComponentConnection(componentRef: ComponentRef): Promise<ComponentConnection> {
    const connections = await this.boundEditorSDK.controllers.listConnections({
      componentRef,
    })

    return getPrimaryConnection(connections)
  }

  public async setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true
  ) {
    const { controllerRef, role, config } = await this.getComponentConnection(connectToRef)
    const mergedConfig = (deepMerge ? _.merge : _.assign)({}, config, connectionConfig)

    return this.boundEditorSDK.controllers.connect({
      connectToRef,
      controllerRef,
      role,
      connectionConfig: mergedConfig,
      isPrimary: true,
    })
  }

  public async saveSite() {
    return this.boundEditorSDK.editor.save()
  }

  public async saveSiteIfUnsaved() {
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    return isSiteSaved || this.saveSite()
  }

  @undoable()
  public async createCollection(componentRef: ComponentRef, biData = {}): Promise<string> {
    const {
      config: { formName, preset },
    } = await this.getComponentConnection(componentRef)
    const collectionId = await this.collectionsApi.createCollection({ preset }, biData)
    await this.collectionsApi.addFieldsToCollection(
      collectionId,
      await this.fields.getFieldsSortByXY(componentRef),
      (fieldComponent, fieldKey) =>
        this.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
    )
    await this.setComponentConnection(componentRef, {
      collectionId: `${componentRef.id}_${collectionId}`,
    })
    await this.editDraft(componentRef, formName, collectionId)
    await this.saveSite()

    return collectionId
  }

  public async getOwnerEmailId() {
    const data = await this.remoteApi.getOwnerEmail().catch(() => null)
    return data ? (isWixEmployeeEmail(data.email) ? '' : data.emailId) : ''
  }

  public async editDraft(componentRef, formName, collectionId = null) {
    const connectedFields = await this.fields.getFieldsSortByXY(componentRef)
    const formData: any = {
      formId: componentRef.id,
      formName,
      fields: _.map(connectedFields, connectedField => ({
        fieldId: connectedField.componentRef.id,
        fieldName: connectedField.crmLabel,
      })),
    }

    if (collectionId) {
      formData.collectionId = collectionId
    }

    return this.remoteApi.editDraft(formData)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  public removeComponent(componentRef: ComponentRef, _biData = {}) {
    return this.removeComponentRef(componentRef)
  }

  public removeComponentRef(componentRef: ComponentRef) {
    return this.boundEditorSDK.components.remove({ componentRef })
  }

  public async getButtonLabel(componentRef: ComponentRef): Promise<string> {
    const { label } = await this.boundEditorSDK.components.data.get({ componentRef })
    return label
  }

  @undoable()
  public updateButtonLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label: buttonLabel },
    })
  }

  public async isTemplate(): Promise<boolean> {
    return !(await this.boundEditorSDK.info.isSiteSaved())
  }

  public getMetaSiteId(): Promise<string> {
    return this.boundEditorSDK.info.getMetaSiteId()
  }

  public getAppDefinitionId(): Promise<string> {
    return this.boundEditorSDK.document.info.getAppDefinitionId()
  }

  public getEditorSessionId(): Promise<string> {
    return this.boundEditorSDK.info.getEditorSessionId()
  }

  public async isMobileMode(): Promise<boolean> {
    return (await this.boundEditorSDK.info.getEditorMode()) === 'mobile'
  }

  private async _isComponentExists(componentRef: ComponentRef) {
    try {
      const componentType = await this.boundEditorSDK.components.getType({ componentRef })
      return !!componentType
    } catch (ex) {
      return false
    }
  }

  private async _isSubscribeFieldExistsWithoutEmailField(
    controllerRef: ComponentRef,
    emailFields: ComponentRef[]
  ) {
    const subscribeFields = await this._findConnectedComponentsByFieldType(
      controllerRef,
      FormsFieldPreset.GENERAL_SUBSCRIBE
    )

    const isSubscribeFieldExistsWithoutEmailField =
      subscribeFields.length > 0 && emailFields.length == 0

    return isSubscribeFieldExistsWithoutEmailField
  }

  private async _isEmailFieldMissing(controllerRef: ComponentRef, emailFields: ComponentRef[]) {
    const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formRef)
    const hasGetSubscribersPlugin = _.chain(config)
      .get('plugins')
      .find({ id: FormPlugin.GET_SUBSCRIBERS })
      .value()

    if (!hasGetSubscribersPlugin) {
      return false
    }

    return emailFields.length === 0
  }

  public async isEmailFieldMissing(controllerRef): Promise<MissingField> {
    const emailFields = await this._findConnectedComponentsByFieldType(
      controllerRef,
      FormsFieldPreset.EMAIL
    )

    const emailFieldMissing =
      (await this._isSubscribeFieldExistsWithoutEmailField(controllerRef, emailFields)) ||
      (await this._isEmailFieldMissing(controllerRef, emailFields))

    return emailFieldMissing ? { type: FieldNameType.PRESET, name: FormsFieldPreset.EMAIL } : null
  }

  public async isFieldMissingByRole(componentRef, role): Promise<MissingField> {
    const ref = await this.findComponentByRole(componentRef, role)
    return ref ? null : { type: FieldNameType.ROLE, name: role }
  }

  public async isMissingFieldByPreset(
    controllerRef: ComponentRef,
    fieldPreset: FieldPreset
  ): Promise<MissingField> {
    const fields = await this._findConnectedComponentsByFieldType(controllerRef, fieldPreset)

    return fields.length === 0 ? { type: FieldNameType.PRESET, name: fieldPreset } : null
  }

  public async isAppWidget(componentRef) {
    return (
      (await this.boundEditorSDK.components.getType({ componentRef })) ===
      'platform.components.AppWidget'
    )
  }

  /*
  TEMP SOLUTION: When we delete form we will get in listConnectedComponents (few lines below) all components
    including other forms in other pages when user duplicated the page - we want to skip the implementation below
    when we got here with duplicated form the user didn't deleted manually
  TODO: Wrap the code below in if-statement when we will have the AppWidget
  `
    const connectedViaAppWidget = await this.boundEditorSDK.components.getType({ componentRef }) === '...AppWidget'
    if (!connectedViaAppWidget) {
      // Do the code below for backwards compatibility
    }
  `
  */
  private async _handleFormDeletion({
    componentRef,
    componentConnection,
    historySnapshotId,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    historySnapshotId: string
  }) {
    const { controllerRef, config } = componentConnection
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.DELETE_FORM,
      esi: esi,
      form_comp_id: componentRef.id,
      template: config.preset,
    })

    const duplicatedForm = await this.findConnectedComponent(controllerRef, ROLE_FORM)
    if (duplicatedForm) {
      return
    }

    if (await this.isMobileMode()) {
      return
    }

    const connectedRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    await Promise.all(
      connectedRefs.map(async connectedComponentRef =>
        this.boundEditorSDK.components.remove({ componentRef: connectedComponentRef })
      )
    )

    const isComponentExists = await this._isComponentExists(controllerRef)
    if (isComponentExists) {
      await this.boundEditorSDK.components.remove({ componentRef: controllerRef })
    }

    await this.boundEditorSDK.history.add({ label: 'History', historySnapshotId })
  }

  // NOTE:
  // This is not working well with duplicated forms, it can return the wrong form component (depends who is first in the array)
  // Should be fixed with AppWidget
  private async _handleFieldDeletion({
    componentConnection,
  }: {
    componentConnection: ComponentConnection
  }) {
    const {
      role,
      controllerRef,
      config: { fieldType },
    } = componentConnection
    const formComponentRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)

    if (!formComponentRef) {
      return
    }

    const formComponentConnection = await this.getComponentConnection(formComponentRef)
    const {
      config: { plugins },
    } = formComponentConnection

    if (role === ROLE_SUBMIT_BUTTON) {
      const {
        config: { preset },
      } = formComponentConnection
      const esi = await this.getEditorSessionId()

      await this._deleteSubmissionButton(formComponentRef, plugins, {
        startBi: {
          form_comp_id: formComponentRef.id,
          template: preset,
          esi,
        },
      })
    } else if (role === ROLE_MESSAGE) {
      const {
        config: { successActionType },
      } = formComponentConnection

      if (
        this._shouldOpenTooltipForDeletedSuccessMessage(
          successActionType || SuccessActionTypes.SHOW_MESSAGE,
          formComponentRef
        )
      ) {
        await this._popRestoreNotification({
          componentRef: formComponentRef,
          missingField: { type: FieldNameType.ROLE, name: ROLE_MESSAGE },
          plugins,
        })
      }
    } else if (role === ROLE_DOWNLOAD_MESSAGE) {
      // TODO merge ifs
      const {
        config: { successActionType },
      } = await this.getComponentConnection(formComponentRef)
      if (successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT && formComponentRef) {
        await this._popRestoreNotification({
          componentRef: formComponentRef,
          missingField: { type: FieldNameType.ROLE, name: ROLE_DOWNLOAD_MESSAGE },
          plugins,
        })
      }
    } else if (fieldType === FormsFieldPreset.EMAIL) {
      const shouldRestoreNotification = await this.isEmailFieldMissing(controllerRef)

      if (shouldRestoreNotification) {
        await this._popRestoreNotification({
          componentRef: formComponentRef,
          missingField: { type: FieldNameType.PRESET, name: FormsFieldPreset.EMAIL },
          plugins,
        })
      } //TODO: Extract this configuration without knowledge about specific plugin
    } else if (REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES.includes(fieldType)) {
      await this._popRestoreNotification({
        componentRef: formComponentRef,
        missingField: { type: FieldNameType.PRESET, name: fieldType },
        plugins,
      })
    }
  }

  private _shouldOpenTooltipForDeletedSuccessMessage(
    successActionType: SuccessActionTypes,
    componentRef: ComponentRef
  ) {
    return successActionType === SuccessActionTypes.SHOW_MESSAGE && componentRef
  }

  public async handleDelete(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
    historySnapshotId
  ) {
    const { role } = componentConnection

    if (role === ROLE_FORM) {
      await this._handleFormDeletion({ componentRef, componentConnection, historySnapshotId })
    } else {
      await this._handleFieldDeletion({ componentConnection })
    }
  }

  public async addMandatoryFieldByPreset(formRef, fieldPreset) {
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    const fieldProps: FieldProperties = getFieldProperties(fieldPreset)

    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)
    const { plugins } = await this.getFormConfigData(formRef)

    this.fields.addField(formRef, preset, plugins, {
      commonStyles,
      extraData: {
        ...fieldProps.extraData,
      },
      fieldType: fieldPreset,
    })
  }

  public async addMandatoryEmailField(formRef) {
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    const email: FieldProperties = getFieldProperties(FormsFieldPreset.EMAIL)
    const commonStyles = await this.style.getFieldsCommonStylesGlobalDesign(formRef)
    const { plugins } = await this.getFormConfigData(formRef)

    this.fields.addField(formRef, preset, plugins, {
      commonStyles,
      extraData: {
        ...email.extraData,
        props: { required: true },
        connectionConfig: { crmType: CRM_TYPES.EMAIL, crmTag: CRM_TAGS.MAIN },
      },
      fieldType: FormsFieldPreset.EMAIL,
    })
  }

  @withBi({ startEvid: EVENTS.EDITOR.DELETE_SUBMISSION_BUTTON })
  private async _deleteSubmissionButton(componentRef, plugins, _biData) {
    this._popRestoreNotification({
      componentRef,
      missingField: { type: FieldNameType.ROLE, name: ROLE_SUBMIT_BUTTON },
      plugins,
    })
  }

  private async _popRestoreNotification({
    componentRef,
    missingField,
    plugins,
  }: {
    componentRef: ComponentRef
    missingField: MissingField
    plugins: Plugin[]
  }) {
    const isMobileMode = await this.isMobileMode()
    const plugin = getActivePlugin(convertPluginsToFormsPlugins(plugins))
    const name = _.camelCase(missingField.name)

    const message = isMobileMode
      ? getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.mobileText`,
        })
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.text`,
        })

    const linkText = isMobileMode
      ? ''
      : getTranslationByPlugin({
          t: translations.t,
          prefix: 'restoreNotification',
          plugin,
          postfix: `${name}.linkText`,
        })

    this.boundEditorSDK.editor
      .showNotification({
        title: translations.t(`restoreNotification.title`),
        message,
        type: 'warning',
        link: {
          caption: linkText,
        },
      })
      .then(shouldRestore => {
        if (!shouldRestore) {
          return
        }

        switch (missingField.name) {
          case ROLE_SUBMIT_BUTTON:
            this.settings.addSubmitButton(componentRef)
            break
          case ROLE_MESSAGE:
            this.settings.addHiddenMessage(componentRef)
            break
          case ROLE_DOWNLOAD_MESSAGE:
            this.settings.addDownloadMessage(componentRef, translations.t('settings.successMessage.download'))
            break
          case FormsFieldPreset.EMAIL:
            this.addMandatoryEmailField(componentRef)
            break
          default:
            if (missingField.type === FieldNameType.PRESET) {
              this.addMandatoryFieldByPreset(componentRef, missingField.name)
            }
            break
        }
      })
  }

  public async isCollectionExists(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ) {
    const {
      config: { collectionId },
    } = componentConnection || (await this.getComponentConnection(componentRef))
    const validCollectionId = getValidCollectionId(componentRef.id, collectionId)
    return this.collectionsApi.isCollectionExists(validCollectionId)
  }

  public async getLocale() {
    return this.boundEditorSDK.environment.getLocale()
  }

  public async sendAllFormsData() {
    const controllers = await this.boundEditorSDK.controllers.listAllControllers()
    const collectionsById = await this.collectionsApi.getCollectionMapById()
    const forms = await Promise.all(
      _.map(controllers, async ({ controllerRef }) => {
        const formRef = await this.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return
        }
        const { config } = await this.getComponentConnection(formRef)
        const validCollectionId = getValidCollectionId(formRef.id, config.collectionId)
        const fields = await this.fields.getFieldsSortByXY(formRef)
        const formattedPlugins = _.map(config.plugins, p => _.toUpper(_.snakeCase(p.id)))

        return {
          formId: formRef.id,
          formName: config.formName,
          collectionId: collectionsById[validCollectionId] && validCollectionId,
          plugins: formattedPlugins,
          fields: _.map(fields, field => {
            const fieldDef: FormFieldDefinition = {
              fieldId: field.componentRef.id,
              fieldName: field.crmLabel,
            }

            if (allowCollectionSync(field.fieldType)) {
              if (field.collectionFieldKey) {
                fieldDef.fieldCollectionKey = field.collectionFieldKey
              }

              if (field.collectionFieldType) {
                fieldDef.fieldCollectionType = field.collectionFieldType
              }
            }

            return fieldDef
          }),
        }
      })
    )
    const formData = {
      forms: _.filter(forms, x => !!x),
    }
    return this.remoteApi.publishSite(formData)
  }

  public async isRegistrationForm(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins: Plugin[] = _.get(config, 'plugins', [])
    return !!_.find(plugins, { id: FormPlugin.REGISTRATION_FORM })
  }

  // TODO: Merge with other config fetch
  // TODO: One possible solution, replace with getPlugins(componentConnection)
  public async getFormConfigData(componentRef) {
    const formCompRef: ComponentRef = await this.findComponentByRole(componentRef, ROLE_FORM)
    const { config } = await this.getComponentConnection(formCompRef)
    const plugins = _.map(_.get(config, 'plugins', []), 'id')
    const preset = _.get(config, 'preset')

    return { plugins, preset }
  }

  public async reportBiFirstSave(oldSiteId) {
    const controllers: {
      controllerRef: ComponentRef
    }[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formRef: ComponentRef = await this.findConnectedComponent(
      controllers[0].controllerRef,
      ROLE_FORM
    )
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.USER_SAVE_TEMPLATE_WITH_FORM,
      form_comp_id: formRef.id,
      template: preset,
      templateId: oldSiteId,
    })
  }

  public async reportBiAppWidgetPasted(componentRef) {
    const formRef: ComponentRef = await this.getFormContainerOfAppWidget(componentRef)
    const {
      config: { preset },
    } = await this.getComponentConnection(formRef)
    this.biLogger.log({
      evid: EVENTS.EDITOR.PASTE_APP_WIDGET,
      source_name: 'copy form',
      form_comp_id: formRef.id,
      template: preset,
    })
  }

  public async createTag(tagName: string) {
    return this.remoteApi.createTag(tagName) // TODO move to add-form only
  }

  public async addHeightToContainers(componentRef: ComponentRef, extraHeight: number) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
    const heights = _.keyBy(
      await this.boundEditorSDK.components.get({
        componentRefs: [componentRef, ...ancestors],
        properties: ['layout'],
      }),
      'componentRef.id'
    )

    const addHeight = async (compRef: ComponentRef) => {
      return this.boundEditorSDK.components.layout.update({
        componentRef: compRef,
        layout: { height: heights[compRef.id].layout.height + extraHeight },
      })
    }

    await addHeight(componentRef)
    await Promise.all(_.map(ancestors, addHeight))
  }

  public async logFetchThemesFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'global design',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public async logFetchPresetsFailed(compRef: ComponentRef | null, reason: string) {
    const esi = await this.getEditorSessionId()
    this.biLogger.log({
      evid: EVENTS.EDITOR.FETCH_FAILED,
      esi,
      name: 'preset',
      reason,
      form_comp_id: _.get(compRef, 'id'),
    })
  }

  public isNewSubmitOptions() {
    return this.experiments.enabled('specs.cx.FormBuilderSubmitSettingsPanel')
  }

  public isConnectPanel() {
    return this.experiments.enabled('specs.cx.FormBuilderConnectGfpp')
  }

  public async panelGoBack() {}
  public async panelUpgrade() {}
  public async panelDelete() {}
}
